import { AppConfig, getConfigForDomain, getLocalConfigForDomain } from './config/appSettingConfig';
import { getLocalStorage, LocalStorageKeys } from './localstorage';

// const currentDomain = window.location.hostname;
const {port, hostname} = window.location;
// const localAppConfig = getLocalConfigForDomain(`${hostname}:${port}`);
// eslint-disable-next-line import/no-mutable-exports
let appConfig = {} as AppConfig; // getConfigForDomain(`${hostname}`);
// const config = getConfigForDomain(hostname);


interface Environment {
  radID: string;
  appName: string;
  nodeEnv: typeof process.env.NODE_ENV;
  mixpanelToken: string;
  razorpayKey: string;
  tinyMCKey: string;
  currency: string;
  country: string;
  theme: string;
  viewerUrl: string;
  azure: {
    account: string;
    sasUrl: string;
  };
  demousers: {
    customer: {
      username: string;
      password: string;
    };
    teleradiologyCompany: {
      username: string;
      password: string;
    };
    radiologist: {
      username: string;
      password: string;
    };
  };
}

const environment: Environment = {
  appName: appConfig.appName  ||  process.env.REACT_APP_APP_NAME || 'RadSmart',
  nodeEnv: process.env.NODE_ENV,
  mixpanelToken: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '',
  razorpayKey: process.env.REACT_APP_RAZOR_PAY_KEY || '',
  tinyMCKey: process.env.REACT_APP_TINYMC_KEY || '91svvuc74a6lizixprn60iqj8gqrpdulv50k4y0r5yxw49c7',
  currency: "INR",
  country: 'INDIA',
  theme: getLocalStorage(LocalStorageKeys.DARK_MODE) || 'light',
  viewerUrl: appConfig.viewerUrl || process.env.REACT_APP_VIEWER_URL || 'https://viewer.radsmart.in',
  azure: {
    account: process.env.REACT_APP_AZURE_ACCOUNT || 'dummy',
    sasUrl: process.env.REACT_APP_AZURE_SAS_URL || 'dummy',
  },
  demousers: {
    customer: {
      username: process.env.REACT_APP_CUSTOMER_USER_USERNAME || '',
      password: process.env.REACT_APP_CUSTOMER_USER_PASSWORD || '',
    },
    teleradiologyCompany: {
      username: process.env.REACT_APP_TELERADIOLOGYCOMPANY_USER_USERNAME || '',
      password: process.env.REACT_APP_TELERADIOLOGYCOMPANY_USER_PASSWORD || '',
    },
    radiologist: {
      username: process.env.REACT_APP_RADIOLOGIST_USER_USERNAME || '',
      password: process.env.REACT_APP_RADIOLOGIST_USER_PASSWORD || '',
    },
  },
  radID: '',
};

const isDev = () => environment.nodeEnv === 'development';

const getHostName=() => hostname === "localhost" ? `${hostname}:${port}` : `${hostname}`;

const isTelefi = () => true; // environment.appName === 'TeleFi.ai';

const getAppName = () => environment.appName;

const setRadID = (id: string) => {
  environment.radID = id;
}
const getRadID = () => environment.radID;

const setEnv=()=>{
  environment.appName = appConfig.appName;

  let url = (appConfig.viewerVerV3 ? appConfig.viewerUrl2 : appConfig.viewerUrl) ||  appConfig.viewerUrl;
  if (!/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }
  environment.viewerUrl = url; // appConfig.viewerUrl;

}
const setAppConfig = (config: AppConfig) => {
  if(config.appName === undefined){
    return;
  }
  appConfig = config;
  setEnv();
}
const setLocalAppConfig = (config: AppConfig) => {
  if(config.appName === undefined){
    return;
  }
  environment.appName = config.appName;
  environment.viewerUrl = config.viewerUrl;
}

const isElectron = () => {
  // Renderer process
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    (window.process as any).type === 'renderer'
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!(process.versions as any).electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to false
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
};

export { environment, isDev, isElectron, getAppName, isTelefi, appConfig, setAppConfig, setLocalAppConfig, getHostName, getRadID, setRadID };
